import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton } from '../../components';

import css from './ConfirmationModal.module.css';

const ConfirmationModal = props => {
  const {
    className,
    rootClassName,
    intl,
    inProgress,
    onAction,
    title,
    description,
    confirmButtonMsg,
    cancelButtonMsg,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  // messages
  const closeButtonMessage = intl.formatMessage({ id: 'ConfirmationModal.later' });
  const confirmButtonMessage =
    confirmButtonMsg || intl.formatMessage({ id: 'ConfirmationModal.confirm' });
  const cancelButtonMessage =
    cancelButtonMsg || intl.formatMessage({ id: 'ConfirmationModal.cancel' });

  return (
    <div>
      <p className={css.modalTitle}>{title}</p>
      <p className={css.modalMessage}>{description}</p>
      <div className={css.modalButtons}>
        <SecondaryButton type="button" className={css.modalButton}>
          {cancelButtonMessage}
        </SecondaryButton>
        <PrimaryButton type="button" className={css.modalButton} inProgress={inProgress}>
          {confirmButtonMessage}
        </PrimaryButton>
      </div>
    </div>
  );
};

const { bool, string, func } = PropTypes;

ConfirmationModal.defaultProps = {
  className: null,
  rootClassName: null,
  inProgress: false,
  onAction: null,
  title: null,
  description: null,
  cancelButtonMsg: null,
};

ConfirmationModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  inProgress: bool.isRequired,
  onAction: func.isRequired,
  title: string.isRequired,
  description: string.isRequired,
  cancelButtonMsg: string,
};

export default injectIntl(ConfirmationModal);
