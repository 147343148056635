/**
 * Export loadData calls from ducks modules of different containers
 */
import { loadData as ArtistProfileLoader } from './ArtistProfilePage/ArtistProfilePage.duck';
import { loadData as AnalyticsPageLoader } from './AnalyticsPage/AnalyticsPage.duck';
import { setInitialValues as BookingPageInitialValues } from './BookingPage/BookingPage.duck';
import { setInitialValues as CheckoutPageInitialValues } from './CheckoutPage/CheckoutPage.duck';
import { loadData as CalendarPageLoader } from './CalendarPage/CalendarPage.duck';
import { loadData as CMSPageLoader } from './CMSPage/CMSPage.duck';
import { loadData as CommentsPageLoader } from './CommentsPage/CommentsPage.duck';
import { loadData as ContactDetailsPageLoader } from './ContactDetailsPage/ContactDetailsPage.duck';
import { loadData as EditExperiencePageLoader } from './EditExperiencePage/EditExperiencePage.duck';
import { loadData as EditListingPageLoader } from './EditListingPage/EditListingPage.duck';
import { loadData as EmailVerificationPageLoader } from './EmailVerificationPage/EmailVerificationPage.duck';
import { loadData as ExperiencePageLoader } from './ExperiencePage/ExperiencePage.duck';
import { loadData as InboxPageLoader } from './InboxPage/InboxPage.duck';
import { loadData as LandingPageLoader } from './LandingPage/LandingPage.duck';
import { loadData as ListingPageLoader } from './ListingPage/ListingPage.duck';
import { loadData as ManageListingsPageLoader } from './ManageListingsPage/ManageListingsPage.duck';
import { loadData as ManageSubscriptionsLoader } from './ManageSubscriptionsPage/ManageSubscriptionsPage.duck';
import { loadData as MembersPageLoader } from './MembersPage/MembersPage.duck';
import { loadData as MeetPageLoader } from './MeetPage/MeetPage.duck';
import { loadData as OrderHistoryPageLoader } from './OrderHistoryPage/OrderHistoryPage.duck';
import { loadData as OrdersPageLoader } from './OrdersPage/OrdersPage.duck';
import { loadData as OrganizationProfileLoader } from './OrganizationProfilePage/OrganizationProfilePage.duck';
import { loadData as OrganizationSettingsLoader } from './OrganizationSettingsPage/OrganizationSettingsPage.duck';
import { loadData as PaymentHistoryPageLoader } from './PaymentHistoryPage/PaymentHistoryPage.duck';
import { loadData as PaymentMethodsPageLoader } from './PaymentMethodsPage/PaymentMethodsPage.duck';
import { loadData as ProfilePageLoader } from './ProfilePage/ProfilePage.duck';
import { loadData as QuickRepliesLoader } from './QuickRepliesPage/QuickRepliesPage.duck';
import { loadData as ReviewsPageLoader } from './ReviewsPage/ReviewsPage.duck';
import { loadData as SearchPageLoader } from './SearchPage/SearchPage.duck';
import { loadData as StripePayoutPageLoader } from './StripePayoutPage/StripePayoutPage.duck';
import { loadData as StreamPageLoader } from './StreamPage/StreamPage.duck';
import { loadData as TeamPermissionLoader } from './TeamPermissionPage/TeamPermissionPage.duck';
import {
  loadData as TransactionPageLoader,
  setInitialValues as TransactionPageInitialValues,
} from './TransactionPage/TransactionPage.duck';
import { loadData as VerifyListingInvitePageLoader } from './VerifyListingInvitePage/VerifyListingInvitePage.duck';
import { loadData as VerifyOrganizationInvitePageLoader } from './VerifyOrganizationInvitePage/VerifyOrganizationInvitePage.duck';
import { loadData as HostsPageLoader } from './HostsPage/HostsPage.duck';
import { loadData as CommunityPageLoader } from './CommunityPage/CommunityPage.duck';
import { loadData as PostPageLoader } from './PostPage/PostPage.duck';

const getPageDataLoadingAPI = () => {
  return {
    ArtistProfilePage: {
      loadData: ArtistProfileLoader,
    },
    AnalyticsPage: {
      loadData: AnalyticsPageLoader,
    },
    BookingPage: {
      setInitialValues: BookingPageInitialValues,
    },
    CalendarPage: {
      loadData: CalendarPageLoader,
    },
    CheckoutPage: {
      setInitialValues: CheckoutPageInitialValues,
    },
    CMSPage: {
      loadData: CMSPageLoader,
    },
    CommentsPage: {
      loadData: CommentsPageLoader,
    },
    ContactDetailsPage: {
      loadData: ContactDetailsPageLoader,
    },
    EditExperiencePage: {
      loadData: EditExperiencePageLoader,
    },
    EditListingPage: {
      loadData: EditListingPageLoader,
    },
    EmailVerificationPage: {
      loadData: EmailVerificationPageLoader,
    },
    ExperiencePage: {
      loadData: ExperiencePageLoader,
    },
    InboxPage: {
      loadData: InboxPageLoader,
    },
    LandingPage: {
      loadData: LandingPageLoader,
    },
    ListingPage: {
      loadData: ListingPageLoader,
    },
    ManageListingsPage: {
      loadData: ManageListingsPageLoader,
    },
    ManageSubscriptionsPage: {
      loadData: ManageSubscriptionsLoader,
    },
    MembersPage: {
      loadData: MembersPageLoader,
    },
    MeetPage: {
      loadData: MeetPageLoader,
    },
    OrderHistoryPage: {
      loadData: OrderHistoryPageLoader,
    },
    OrdersPage: {
      loadData: OrdersPageLoader,
    },
    OrganizationProfilePage: {
      loadData: OrganizationProfileLoader,
    },
    OrganizationSettingsPage: {
      loadData: OrganizationSettingsLoader,
    },
    PaymentHistoryPage: {
      loadData: PaymentHistoryPageLoader,
    },
    PaymentMethodsPage: {
      loadData: PaymentMethodsPageLoader,
    },
    ProfilePage: {
      loadData: ProfilePageLoader,
    },
    QuickRepliesPage: {
      loadData: QuickRepliesLoader,
    },
    ReviewsPage: {
      loadData: ReviewsPageLoader,
    },
    SearchPage: {
      loadData: SearchPageLoader,
    },
    StripePayoutPage: {
      loadData: StripePayoutPageLoader,
    },
    StreamPage: {
      loadData: StreamPageLoader,
    },
    TeamPermissionPage: {
      loadData: TeamPermissionLoader,
    },
    TransactionPage: {
      loadData: TransactionPageLoader,
      setInitialValues: TransactionPageInitialValues,
    },
    VerifyListingInvitePage: {
      loadData: VerifyListingInvitePageLoader,
    },
    VerifyOrganizationInvitePage: {
      loadData: VerifyOrganizationInvitePageLoader,
    },
    HostsPage: {
      loadData: HostsPageLoader,
    },
    CommunityPage: {
      loadData: CommunityPageLoader,
    },
    PostPage: {
      loadData: PostPageLoader,
    },
  };
};

export default getPageDataLoadingAPI;
