import React from 'react';
import PropTypes from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { ExperienceCard } from '..';
import classNames from 'classnames';

import css from './SectionExperiences.module.css';

const SectionExperiences = props => {
  const {
    rootClassName,
    className,
    experiences,
    queryExperiencesInProgress,
    queryExperiencesError,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (queryExperiencesInProgress || queryExperiencesError) {
    return null;
  }

  return (
    <div className={classes}>
      <h2 className={css.title}>
        <FormattedMessage id="SectionExperiences.title" />
      </h2>
      <div className={css.experiences}>
        {experiences.map(l => {
          return <ExperienceCard key={l.id.uuid} listing={l} />;
        })}
      </div>
    </div>
  );
};

SectionExperiences.defaultProps = {
  rootClassName: null,
  className: null,
  queryExperiencesInProgress: false,
  queryExperiencesError: null,
};

const { arrayOf, bool, string } = PropTypes;

SectionExperiences.propTypes = {
  rootClassName: string,
  className: string,

  experiences: arrayOf(propTypes.listing),
  queryExperiencesInProgress: bool.isRequired,
  queryExperiencesError: propTypes.error,
};

export default SectionExperiences;
