import React from 'react';
import { string } from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { userDisplayNameAsString } from '../../util/data';
import { NamedLink, AvatarLarge } from '../../components';
import classNames from 'classnames';

import css from './HostCard.module.css';

const findUserData = (user, data) => data?.find(d => d.id === user.id.uuid);

const HostCard = props => {
  const { rootClassName, className, user, teamFromPublicData } = props;
  const classes = classNames(rootClassName || css.root, className);

  const userData = findUserData(user, teamFromPublicData);
  const displayName = userDisplayNameAsString(user);
  return (
    <NamedLink className={classes} name="ArtistProfilePage" params={{ id: user.id.uuid }}>
      <AvatarLarge className={css.avatar} user={user} disableProfileLink />
      <div className={css.content}>
        <h3 className={css.heading}>{displayName}</h3>
        {userData?.credit ? <span className={css.infoText}>{userData.credit}</span> : null}
        {userData?.year ? (
          <span className={css.infoText}>
            <FormattedMessage
              id="HostCard.year"
              values={{
                start: userData.year.start,
                end: userData.year.end,
              }}
            />
          </span>
        ) : null}
      </div>
    </NamedLink>
  );
};

HostCard.defaultProps = {
  rootClassName: null,
  className: null,
};

HostCard.propTypes = {
  rootClassName: string,
  className: string,
  user: propTypes.user,
  teamFromPublicData: propTypes.team,
};

export default HostCard;
