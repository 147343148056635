import React from 'react';
import { oneOfType, arrayOf, bool, func, number, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import { Form, SecondaryButton } from '../../components';
import { notification } from '../../util/notification';
import { emailAPI } from '../../util/api';
import config from '../../config';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { ACTION_TYPE_ADD } from '../../containers/OrganizationSettingsPage/OrganizationSettingsPage';
import OrganizationProfiles from './OrganizationProfiles';
import css from './OrganizationSettingsForm.module.css';
import { confirmModal } from '../../util/data';

const DEFAULT_ORGANIZATION_ID = 0;
const SUBMIT_DELAY = 800;

const OrganizationSettingsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        className,
        rootClassName,
        currentUser,
        handleSubmit,
        intl,
        form,
        invalid,
        pristine,
        values,
        listings,
        saveOrganizationProfilesInProgress,
        saveOrganizationProfilesError,
        uploadImageInProgress,
        selectedOrganization,
        isSingleOrganizationView,
        activeOrganizationId,
        createOrUpdateSuccessMessage,
        setActiveOrganizationId,
        setActionType,
        toggleInvalidDeleteModal,
      } = fieldRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = saveOrganizationProfilesInProgress || uploadImageInProgress;
      const submitDisabled = invalid || pristine || submitInProgress;

      const organizationProfilesFromValues = values.organizationProfiles;
      const hasOrganizations = organizationProfilesFromValues?.length > 0;

      // Fn for adding new profile that pushes the new
      // field in organizationProfiles and opens the
      // single profile view.
      const handleAddProfile = () => {
        form.mutators.push('organizationProfiles', undefined);

        const organizationId = organizationProfilesFromValues?.length || DEFAULT_ORGANIZATION_ID;
        setActiveOrganizationId(organizationId);
        setActionType(ACTION_TYPE_ADD);
      };

      const renderFormContent = isSingleOrganizationView ? null : (
        <div className={css.formContent}>
          <div>
            <h3 className={css.formTitle}>
              <FormattedMessage id="OrganizationSettingsPage.formTitle" />
            </h3>
            <p className={css.formText}>
              <FormattedMessage id="OrganizationSettingsPage.formText" />
            </p>
          </div>
        </div>
      );

      const renderNoOrganizationsUI = !hasOrganizations ? (
        <div className={css.organizationProfiles} onClick={() => handleAddProfile()}>
          <div className={classNames(css.organizationProfile, css.createOrganizationProfile)}>
            <div className={css.organizationProfileText}>
              <h4 className={css.organizationProfileTitle}>
                <FormattedMessage id="OrganizationSettingsForm.createOrganizationProfile" />
              </h4>
              <p className={css.organizationProfileBio}>
                <FormattedMessage
                  id="OrganizationSettingsForm.createOrganizationProfileText"
                  values={{ siteTitle: config.siteTitle }}
                />
              </p>
            </div>
          </div>
        </div>
      ) : null;

      const renderAddProfileButton = isSingleOrganizationView ? null : (
        <SecondaryButton
          className={css.addOrganizationProfileButton}
          type="button"
          onClick={handleAddProfile}
        >
          <FormattedMessage id="OrganizationSettingsForm.addOrganizationProfile" />
        </SecondaryButton>
      );

      const errorMessage = saveOrganizationProfilesError ? (
        <p className={css.error}>
          <FormattedMessage id="OrganizationSettingsForm.saveError" />
        </p>
      ) : null;

      return (
        <Form
          className={classes}
          onSubmit={async values => {
            await handleSubmit(values);

            setTimeout(() => {
              setActiveOrganizationId(null);
              notification.success(createOrUpdateSuccessMessage);
            }, [SUBMIT_DELAY]);
          }}
        >
          {renderFormContent}
          {renderAddProfileButton}
          {renderNoOrganizationsUI}
          {errorMessage}
          <OrganizationProfiles
            intl={intl}
            form={form}
            submitInProgress={submitInProgress}
            submitDisabled={submitDisabled}
            currentUser={currentUser}
            listings={listings}
            organizationProfilesFromValues={organizationProfilesFromValues}
            hasOrganizations={hasOrganizations}
            selectedOrganization={selectedOrganization}
            isSingleOrganizationView={isSingleOrganizationView}
            activeOrganizationId={activeOrganizationId}
            setActiveOrganizationId={setActiveOrganizationId}
            setActionType={setActionType}
            onSubmitDeleteProfile={async (values, currentDeletedProfile, deleteFn) => {
              if (
                window.confirm(
                  intl.formatMessage(
                    {
                      id: 'OrganizationSettingsForm.confirmDeleteOrganizationProfile',
                    },
                    { name: currentDeletedProfile.name }
                  )
                )
              ) {
                await deleteFn();
                await handleSubmit(values);
                emailAPI.organizationProfiles.delete({
                  recipient: {
                    id: currentUser.id.uuid,
                    email: currentUser.attributes.email,
                    name: currentUser.attributes.profile.displayName,
                  },
                  organization: currentDeletedProfile,
                });
              }
            }}
            toggleInvalidDeleteModal={toggleInvalidDeleteModal}
          />
        </Form>
      );
    }}
  />
);

OrganizationSettingsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,

  currentUser: null,
  listings: [],
  updateInProgress: false,
  updateError: null,
  isSingleOrganizationView: false,
  activeOrganizationId: null,
  setActiveOrganizationId: null,
  setActionType: null,
};

OrganizationSettingsFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  listings: arrayOf(propTypes.listing),
  updateInProgress: bool.isRequired,
  updateError: propTypes.error,
  selectedOrganization: oneOfType([propTypes.organization, propTypes.ownOrganization]),
  isSingleOrganizationView: bool.isRequired,
  activeOrganizationId: number,
  setActiveOrganizationId: func.isRequired,
  setActionType: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const OrganizationSettingsForm = compose(injectIntl)(OrganizationSettingsFormComponent);

OrganizationSettingsForm.displayName = 'OrganizationSettingsForm';

export default OrganizationSettingsForm;
