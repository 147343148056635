import React, { useState } from 'react';
import { func, bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink, IconLanguage, LanguageModal, InlineTextButton } from '../../components';
import { NewsletterForm } from '../../forms';
import { subscribeToList } from '../../ducks/mailchimp.duck';
import classNames from 'classnames';

import CanadaIcon from './icons/CanadaIcon';

import css from './Footer.module.css';

const NewsletterSignup = props => {
  const {
    currentUser,
    onSubscribeToList,
    subscribeToListInProgress,
    subscribeToListError,
    subscribeToListSubmitted,
  } = props;

  const firstName = currentUser?.attributes?.profile?.firstName || '';
  const lastName = currentUser?.attributes?.profile?.lastName || '';

  const handleSubmit = (values, form) => {
    const { email } = values;
    const subscribingUser = { email, firstName, lastName };

    onSubscribeToList({
      listId: process.env.REACT_APP_MAILCHIMP_NEWSLETTER_LIST_ID,
      subscribingUser,
    })
      .then(() => {
        form.reset();
        form.resetFieldState('email');
      })
      .catch(error => {
        console.error('Subscription error:', error);
        form.reset();
        form.resetFieldState('email');
      });
  };

  return (
    <div className={css.newsletter}>
      <h2 className={css.newsletterTitle}>
        <FormattedMessage id="Footer.newsletterTitle" />
      </h2>
      {!subscribeToListSubmitted ? (
        <NewsletterForm
          className={css.emailForm}
          onSubmit={handleSubmit}
          inProgress={subscribeToListInProgress}
        />
      ) : (
        <p className={css.newsletterSuccessMessage}>
          <FormattedMessage id="Footer.newsletterSuccessMessage" />
        </p>
      )}
      {subscribeToListError ? (
        <p className={css.newsletterErrorMessage}>
          <FormattedMessage id="Footer.newsletterErrorMessage" />
        </p>
      ) : null}
    </div>
  );
};

const LinkColumn = props => {
  const { title, links } = props;

  return (
    <div className={css.linkColumn}>
      <h3 className={css.linkColumnTitle}>{title}</h3>
      <ul className={css.linkColumnList}>
        {links.map((link, index) => (
          <li key={`${link.name}_${index}`} className={css.linkColumnListItem}>
            <NamedLink className={css.linkColumnListItemLink} name={link.name}>
              {link.label}
            </NamedLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

const LinkSections = () => (
  <div className={css.linkSections}>
    <LinkColumn
      title="Features"
      links={[
        { name: 'LandingPage', label: 'Engage beyond the stage' },
        { name: 'LandingPage', label: 'Thrive with Attractr' },
        { name: 'LandingPage', label: 'Grow your audiences' },
        { name: 'LandingPage', label: 'User badge' },
      ]}
    />
    <LinkColumn
      title="Community"
      links={[
        { name: 'HelpPage', label: 'Help center' },
        { name: 'CommunityPage', label: 'Community forum' },
        { name: 'CommunityGuidelinesPage', label: 'Community Guidelines' },
      ]}
    />
    <LinkColumn
      title="About"
      links={[
        { name: 'AboutPage', label: 'Our Story' },
        { name: 'TermsOfServicePage', label: 'Terms' },
        { name: 'CommunityGuidelinesPage', label: 'Privacy Policy' },
      ]}
    />
  </div>
);

const LanguageSelector = props => {
  const { onOpenLanguageModal } = props;
  return (
    <InlineTextButton className={css.languageSelector} type="button" onClick={onOpenLanguageModal}>
      <IconLanguage className={css.languageSelectorIcon} />
      <p className={css.languageSelectorText}>English (US)</p>
    </InlineTextButton>
  );
};

/**
 * Contains the main content of the footer including newsletter and link sections
 */
const MainContent = props => (
  <div className={css.mainContent}>
    <NewsletterSignup {...props} />
    <LinkSections />
  </div>
);

/**
 * Represents the section showcasing endorsement by external entities
 */
const TrustedBy = () => (
  <div className={css.trustedBy}>
    <h3 className={css.trustedByTitle}>Trusted by</h3>
    <div className={css.trustedByContent}>
      <div className={css.trustedByTextContent}>
        <p className={css.trustedByText}>Financé par le gouvernement du Canada</p>
        <p className={css.trustedByText}>Funded by the government of Canada</p>
      </div>
      <CanadaIcon className={css.trustedByIcon} />
    </div>
  </div>
);

/**
 * Footer bottom content including copyright and language selector
 */
const BottomContent = props => {
  const { onOpenLanguageModal } = props;
  return (
    <div className={css.bottomContent}>
      <div className={css.bottomContentWrapper}>
        <div className={css.attractrFooter}>© 2024 Attractr</div>
        <LanguageSelector onOpenLanguageModal={onOpenLanguageModal} />
      </div>
    </div>
  );
};

const Footer = props => {
  const {
    rootClassName,
    className,
    currentUser,
    onSubscribeToList,
    subscribeToListInProgress,
    subscribeToListError,
    subscribeToListSubmitted,
    onManageDisableScrolling,
  } = props;

  const [languageModalOpen, setLanguageModalOpen] = useState(false);

  const classes = classNames(rootClassName || css.root, className);
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  return (
    <div className={classes}>
      <div className={css.content}>
        <MainContent
          currentUser={ensuredCurrentUser}
          onSubscribeToList={onSubscribeToList}
          subscribeToListInProgress={subscribeToListInProgress}
          subscribeToListError={subscribeToListError}
          subscribeToListSubmitted={subscribeToListSubmitted}
        />
        <TrustedBy />
      </div>
      <BottomContent onOpenLanguageModal={() => setLanguageModalOpen(true)} />
      <LanguageModal
        id="LanguageModal"
        isOpen={languageModalOpen}
        onCloseModal={() => setLanguageModalOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
      />
    </div>
  );
};

Footer.defaultProps = {
  currentUser: null,

  // mailchimp
  onSubscribeToList: null,
  subscribeToListInProgress: false,
  subscribeToListError: null,
  subscribeToListSubmitted: false,
};

Footer.propTypes = {
  currentUser: propTypes.currentUser,

  // mailchimp
  onSubscribeToList: func.isRequired,
  subscribeToListInProgress: bool.isRequired,
  subscribeToListError: propTypes.error,
  subscribeToListSubmitted: bool.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    subscribeToListInProgress,
    subscribeToListError,
    subscribeToListSubmitted,
  } = state.mailchimp;

  return {
    currentUser,
    subscribeToListInProgress,
    subscribeToListError,
    subscribeToListSubmitted,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSubscribeToList: bodyParams => dispatch(subscribeToList(bodyParams)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Footer);
