import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';
import classNames from 'classnames';

import css from './SectionJoin.module.css';

const SectionJoin = props => {
  const { rootClassName, className, currentUser, isCurrentUserArtist } = props;
  const classes = classNames(css.root, rootClassName, className);

  return (
    <div className={classes}>
      <div className={css.content}>
        <h1 className={css.title}>
          <FormattedMessage id="SectionJoin.title" />
        </h1>
        <p className={css.subTitle}>
          <FormattedMessage id="SectionJoin.subTitle" />
        </p>
        <div className={css.buttons}>
          {currentUser ? null : (
            <NamedLink name="RoleSelectionPage" className={css.button}>
              <FormattedMessage id="SectionJoin.buttonFirstText" />
            </NamedLink>
          )}
          {currentUser ? (
            isCurrentUserArtist ? (
              <NamedLink name="CreatorsPage" className={css.button}>
                <FormattedMessage id="SectionJoin.buttonSecondText" />
              </NamedLink>
            ) : null
          ) : (
            <NamedLink name="CreatorsPage" className={css.button}>
              <FormattedMessage id="SectionJoin.buttonSecondText" />
            </NamedLink>
          )}
        </div>
      </div>
    </div>
  );
};

SectionJoin.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionJoin.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionJoin;
