import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { arrayOf, array, string, bool, func } from 'prop-types';
import { THUMB_TYPE_DISLIKE, THUMB_TYPE_LIKE, propTypes } from '../../util/types';
import classNames from 'classnames';
import {
  Avatar,
  UserDisplayName,
  ExpandableBio,
  InlineTextButton,
  IconThumb,
  IconReviewStar,
  Badges,
} from '../../components';
import moment from 'moment';

import css from './Reviews.module.css';

const REVIEW_LENGTH = 600;

export const Review = props => {
  const {
    review,
    currentUserReactions,
    likeOrDislikeInProgress,
    likeOrDislikeError,
    handleReviewLikeOrDislike,
    isFeatured,
    intl,
  } = props;

  const authorForAvatar = review.author ? review.author : { attributes: { deleted: true } };
  const date = review.attributes.createdAt;
  const thumbButtonDisabled = likeOrDislikeInProgress || !!likeOrDislikeError;

  const isLiked = !!currentUserReactions.find(
    reaction => reaction.reviewId === review.id && reaction.type === 'like'
  );
  const isDisliked = !!currentUserReactions.find(
    reaction => reaction.reviewId === review.id && reaction.type === 'dislike'
  );

  const reviewRating = (
    <div className={css.reviewRating}>
      <IconReviewStar className={css.reviewRatingStar} isFilled />
      <span className={css.reviewRatingText}>
        <FormattedMessage
          id="Reviews.rating"
          values={{
            rating: <span className={css.reviewRatingCount}>{review.attributes.rating}</span>,
          }}
        />
      </span>
    </div>
  );

  return (
    <div className={css.review}>
      <Avatar className={css.avatar} user={authorForAvatar} />
      <div className={css.reviewContent}>
        <div className={css.reviewInfo}>
          {isFeatured ? (
            <div className={css.featuredReviewTextContainer}>
              {reviewRating}
              <span className={css.featuredReviewText}>
                <FormattedMessage id="Reviews.featuredReview" />
              </span>
            </div>
          ) : (
            reviewRating
          )}
          <p className={css.reviewInfoDetails}>
            {review.author ? (
              <UserDisplayName className={css.userDisplayName} user={review.author} intl={intl} />
            ) : (
              <span className={css.userDisplayName}>
                {intl.formatMessage({ id: 'Reviews.deletedUser' })}
              </span>
            )}
            <Badges user={authorForAvatar} />
            <span className={css.reviewDate}>{moment(date).fromNow()}</span>
          </p>
        </div>
        <ExpandableBio
          className={css.reviewBio}
          showMoreClassName={css.reviewShowMore}
          bio={review.attributes.content}
          maxLength={REVIEW_LENGTH}
        />
        <div className={css.reviewThumbButtons}>
          <div className={css.reviewThumbButtonContainer}>
            <InlineTextButton
              type="button"
              className={css.reviewThumbButton}
              onClick={() => handleReviewLikeOrDislike(THUMB_TYPE_LIKE, review.id)}
              disabled={thumbButtonDisabled}
            >
              <IconThumb className={css.reviewThumbIcon} direction="up" isFilled={isLiked} />
            </InlineTextButton>
            <span className={css.reviewThumbCount}>{review.attributes.likes}</span>
          </div>
          <div className={css.reviewThumbButtonContainer}>
            <InlineTextButton
              type="button"
              className={css.reviewThumbButton}
              onClick={() => handleReviewLikeOrDislike(THUMB_TYPE_DISLIKE, review.id)}
              disabled={thumbButtonDisabled}
            >
              <IconThumb className={css.reviewThumbIcon} direction="down" isFilled={isDisliked} />
            </InlineTextButton>
            <span className={css.reviewThumbCount}>{review.attributes.dislikes}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

Review.propTypes = {
  review: propTypes.showReview.isRequired,
  currentUserReactions: array.isRequired,
  intl: intlShape.isRequired,
};

const ReviewsComponent = props => {
  const {
    className,
    rootClassName,
    reviewsClassName,
    reviews,
    currentUserReactions,
    likeOrDislikeInProgress,
    likeOrDislikeError,
    handleReviewLikeOrDislike,
    intl,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <ul className={classNames(css.reviews, reviewsClassName)}>
        {reviews.map(r => {
          return (
            <li key={`Review_${r?.id?.uuid || r?.id}`} className={css.reviewItem}>
              <Review
                review={r}
                intl={intl}
                currentUserReactions={currentUserReactions}
                likeOrDislikeInProgress={likeOrDislikeInProgress}
                likeOrDislikeError={likeOrDislikeError}
                handleReviewLikeOrDislike={handleReviewLikeOrDislike}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

ReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviewsClassName: null,
  reviews: [],
  currentUserReactions: [],
  likeOrDislikeInProgress: false,
  likeOrDislikeError: null,
};

ReviewsComponent.propTypes = {
  className: string,
  rootCalssName: string,
  reviewsClassName: string,
  reviews: arrayOf(propTypes.showReview),
  currentUserReactions: array,
  likeOrDislikeInProgress: bool.isRequired,
  likeOrDislikeError: propTypes.error,
  handleReviewLikeOrDislike: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Reviews = injectIntl(ReviewsComponent);

export default Reviews;
