import { util as sdkUtil } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchOrganizationProfiles } from '../../ducks/organizations.duck';
import { organizationSettingsAPI } from '../../util/api';
import { queryRecentlyViewedListings } from '../../ducks/user.duck';

// ================ Action types ================ //

export const SHOW_ORGANIZATION_PROFILE_REQUEST =
  'app/OrganizationSettingsPage/SHOW_ORGANIZATION_PROFILE_REQUEST';
export const SHOW_ORGANIZATION_PROFILE_SUCCESS =
  'app/OrganizationSettingsPage/SHOW_ORGANIZATION_PROFILE_SUCCESS';
export const SHOW_ORGANIZATION_PROFILE_ERROR =
  'app/OrganizationSettingsPage/SHOW_ORGANIZATION_PROFILE_ERROR';

export const FETCH_LISTINGS_REQUEST = 'app/OrganizationProfilePage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/OrganizationProfilePage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/OrganizationProfilePage/FETCH_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  showOrganizationProfileInProgress: false,
  showOrganizationProfileError: null,
  organizationProfile: null,
  fetchListingsInProgress: false,
  fetchListingsError: null,
  listingIds: [],
};

const resultIds = data => data.data.map(d => d.id);

export default function organizationProfilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SHOW_ORGANIZATION_PROFILE_REQUEST:
      return {
        ...state,
        showOrganizationProfileInProgress: true,
        showOrganizationProfileError: null,
        organizationProfile: null,
      };
    case SHOW_ORGANIZATION_PROFILE_SUCCESS:
      return {
        ...state,
        showOrganizationProfileInProgress: false,
        showOrganizationProfileError: null,
        organizationProfile: payload.data,
      };
    case SHOW_ORGANIZATION_PROFILE_ERROR:
      return {
        ...state,
        showOrganizationProfileInProgress: false,
        showOrganizationProfileError: payload,
      };

    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        fetchListingsInProgress: true,
        fetchListingsError: null,
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        fetchListingsInProgress: false,
        fetchListingsError: null,
        listingIds: resultIds(payload.data),
      };
    case FETCH_LISTINGS_ERROR:
      return {
        ...state,
        shfetchListingsProgress: false,
        fetchListingsError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ===== =========== //

export const showOrganizationProfileRequest = () => ({
  type: SHOW_ORGANIZATION_PROFILE_REQUEST,
});
export const showOrganizationProfileSuccess = response => ({
  type: SHOW_ORGANIZATION_PROFILE_SUCCESS,
  payload: { data: response.data.data },
});
export const showOrganizationProfileError = e => ({
  type: SHOW_ORGANIZATION_PROFILE_ERROR,
  error: true,
  payload: e,
});

export const fetchListingsRequest = () => ({
  type: FETCH_LISTINGS_REQUEST,
});
export const fetchListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});
export const fetchListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const showOrganizationProfile = id => (dispatch, getState, sdk) => {
  dispatch(showOrganizationProfileRequest());

  return organizationSettingsAPI
    .show({ id })
    .then(response => {
      dispatch(showOrganizationProfileSuccess(response));
      return response;
    })
    .catch(e => dispatch(showOrganizationProfileError(storableError(e.response))));
};

export const fetchListings = organizationId => (dispatch, getState, sdk) => {
  dispatch(fetchListingsRequest());

  return sdk.listings
    .query({
      pub_organizationId: organizationId,
      include: ['images'],
      'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'metadata'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': [
        'variants.landscape-crop',
        'variants.landscape-crop2x',

        'variants.portrait-crop',
        'variants.portrait-crop2x',
      ],
      'imageVariant.portrait-crop': sdkUtil.objectQueryString({
        w: 280,
        h: 392,
        fit: 'crop',
      }),
      'imageVariant.portrait-crop2x': sdkUtil.objectQueryString({
        w: 560,
        h: 784,
        fit: 'crop',
      }),
      'limit.images': 1,
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchListingsSuccess(response));
      return response;
    })
    .catch(e => dispatch(fetchListingsError(storableError(e))));
};

export const loadData = params => (dispatch, getState, sdk) => {
  return Promise.all([
    dispatch(showOrganizationProfile(params.id)),
    dispatch(fetchListings(params.id)),
    dispatch(queryRecentlyViewedListings()),
    dispatch(fetchOrganizationProfiles()),
  ]);
};
