import React, { useEffect, useState } from 'react';
import { string, bool, func } from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { BetaSignupForm } from '../../forms';
import classNames from 'classnames';
import config from '../../config';

import heroImage from './images/hero-image.png';
import css from './SectionHero.module.css';

const ScrollIcon = props => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 48 48"
    >
      <path
        stroke="#4a4a4a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M24 3c9.168 0 15.662 6.181 15.924 15.345C39.972 20.041 40 21.92 40 24s-.028 3.96-.076 5.655C39.662 38.819 33.168 45 24 45S8.338 38.819 8.076 29.655A198.331 198.331 0 018 24c0-2.08.028-3.96.076-5.654C8.338 9.18 14.832 3 24 3zM24 11v6"
      ></path>
    </svg>
  );
};

// Extracted function to handle Mailchimp errors
const mailchimpErrorMessage = (intl, error) => {
  if (error.name === 'Member Exists') {
    return intl.formatMessage({ id: 'SectionHero.errorMemberExist' });
  }

  return intl.formatMessage({ id: 'SectionHero.error' });
};

const SectionHero = props => {
  const [mounted, setMounted] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const {
    rootClassName,
    className,
    intl,
    onSubscribeToList,
    subscribeToListInProgress,
    subscribeToListError,
    isWithListingsLayout,
  } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  // Simplified function to handle form submission
  const handleSubmit = (values, form) => {
    const { email } = values;

    const subscribingUser = {
      email,
    };

    onSubscribeToList({
      listId: process.env.REACT_APP_MAILCHIMP_BETA_SIGNUPS_LIST_ID,
      subscribingUser,
    }).then(() => {
      form.reset();
      setSubmitted(true);
    });
  };

  const betaSignupContent = () => {
    if (subscribeToListError) {
      return <p className={css.error}>{mailchimpErrorMessage(intl, subscribeToListError)}</p>;
    } else if (isSubmitted) {
      return (
        <p className={css.submittedText}>
          <FormattedMessage id="SectionHero.submittedText" />
        </p>
      );
    }

    return (
      <BetaSignupForm
        className={css.signupForm}
        onSubmit={handleSubmit}
        inProgress={subscribeToListInProgress}
      />
    );
  };

  const classes = classNames(rootClassName || css.root, className, {
    [css.rootWithListings]: isWithListingsLayout,
  });

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={classNames(css.heroMainTitle, { [css.heroMainTitleFEDelay]: mounted })}>
          <FormattedMessage
            id="SectionHero.title"
            values={{
              highlightedText: (
                <span className={css.highlighted}>
                  <FormattedMessage id="SectionHero.highlighted" />
                </span>
              ),
            }}
          />
        </h1>

        {isWithListingsLayout ? null : (
          <h2 className={classNames(css.heroSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
            <FormattedMessage id="SectionHero.subTitle" />
          </h2>
        )}
        {isWithListingsLayout ? null : betaSignupContent()}
      </div>
      <div className={css.scrollIconContainer}>
        <ScrollIcon className={css.scrollIcon} />
      </div>
      <div className={css.imageContent}>
        <img className={css.heroImage} src={heroImage} alt={config.siteTitle} />
      </div>
    </div>
  );
};

SectionHero.defaultProps = {
  rootClassName: null,
  className: null,

  // mailchimp
  onSubscribeToList: null,
  subscribeToListInProgress: false,
  subscribeToListError: null,

  isWithListingsLayout: false,
};

SectionHero.propTypes = {
  rootClassName: string,
  className: string,

  // mailchimp
  onSubscribeToList: func.isRequired,
  subscribeToListInProgress: bool.isRequired,
  subscribeToListError: propTypes.error,

  isWithListingsLayout: bool.isRequired,
};

export default SectionHero;
