import pick from 'lodash/pick';
import config from '../../config';
import { resetToStartOfDay, getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { types as sdkTypes, util as sdkUtil } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { integrationAPI } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { TRANSITION_ENQUIRE } from '../../util/transaction';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import {
  fetchCurrentUser,
  fetchCurrentUserHasOrdersSuccess,
  queryRecentlyViewedListings,
  saveRecentlyViewedListingId,
} from '../../ducks/user.duck';
import { payoutsEnabled } from '../../ducks/stripe.duck.js';
import { fetchOrganizationProfiles } from '../../ducks/organizations.duck';
import { fetchComments } from '../CommentsPage/CommentsPage.duck';

const { UUID } = sdkTypes;

const QUERY_EXPERIENCES_RESULT_PAGE_SIZE = 12;

const listingsIncludeParams = {
  include: ['author', 'author.profileImage', 'images'],
  'fields.image': [
    // Listing page
    'variants.landscape-crop',
    'variants.landscape-crop2x',
    'variants.landscape-crop4x',
    'variants.landscape-crop6x',
    'variants.portrait-crop',
    'variants.portrait-crop2x',

    // Social media
    'variants.facebook',
    'variants.twitter',

    // Image carousel
    'variants.scaled-small',
    'variants.scaled-medium',
    'variants.scaled-large',
    'variants.scaled-xlarge',

    // Avatars
    'variants.square-small',
    'variants.square-small2x',
  ],
  'imageVariant.hero-scale': 'w:1440;h:400;fit:scale',
  'imageVariant.hero-crop': 'w:1440;h:400;fit:crop',
  'imageVariant.portrait-crop': sdkUtil.objectQueryString({
    w: 280,
    h: 392,
    fit: 'crop',
  }),
  'imageVariant.portrait-crop2x': sdkUtil.objectQueryString({
    w: 560,
    h: 784,
    fit: 'crop',
  }),
};

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ExperiencePage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ExperiencePage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ExperiencePage/SHOW_LISTING_ERROR';

export const QUERY_EXPERIENCES_REQUEST = 'app/ExperiencePage/QUERY_EXPERIENCES_REQUEST';
export const QUERY_EXPERIENCES_SUCCESS = 'app/ExperiencePage/QUERY_EXPERIENCES_SUCCESS';
export const QUERY_EXPERIENCES_ERROR = 'app/ExperiencePage/QUERY_EXPERIENCES_ERROR';

export const FETCH_AVAILABILITY_EXCEPTIONS_REQUEST =
  'app/ExperiencePage/FETCH_AVAILABILITY_EXCEPTIONS_REQUEST';
export const FETCH_AVAILABILITY_EXCEPTIONS_SUCCESS =
  'app/ExperiencePage/FETCH_AVAILABILITY_EXCEPTIONS_SUCCESS';
export const FETCH_AVAILABILITY_EXCEPTIONS_ERROR =
  'app/ExperiencePage/FETCH_AVAILABILITY_EXCEPTIONS_ERROR';

export const SEND_ENQUIRY_REQUEST = 'app/ExperiencePage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/ExperiencePage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/ExperiencePage/SEND_ENQUIRY_ERROR';

export const QUERY_USERS_REQUEST = 'app/ExperiencePage/QUERY_USERS_REQUEST';
export const QUERY_USERS_SUCCESS = 'app/ExperiencePage/QUERY_USERS_SUCCESS';
export const QUERY_USERS_ERROR = 'app/ExperiencePage/QUERY_USERS_ERROR';

export const FETCH_SHOW_LISTING_REQUEST = 'app/ExperiencePage/FETCH_SHOW_LISTING_REQUEST';
export const FETCH_SHOW_LISTING_SUCCESS = 'app/ExperiencePage/FETCH_SHOW_LISTING_SUCCESS';
export const FETCH_SHOW_LISTING_ERROR = 'app/ExperiencePage/FETCH_SHOW_LISTING_ERROR';

export const CREATE_FREE_SUBSCRIPTION_REQUEST =
  'app/ExperiencePage/CREATE_FREE_SUBSCRIPTION_REQUEST';
export const CREATE_FREE_SUBSCRIPTION_SUCCESS =
  'app/ExperiencePage/CREATE_FREE_SUBSCRIPTION_SUCCESS';
export const CREATE_FREE_SUBSCRIPTION_ERROR = 'app/ExperiencePage/CREATE_FREE_SUBSCRIPTION_ERROR';

export const FILTER_AVAILABILITY_EXCEPTIONS_SUCCESS =
  'app/ExperiencePage/FILTER_AVAILABILITY_EXCEPTIONS_SUCCESS';
export const FETCH_AUTHOR_PAYOUTS_ENABLED = 'app/ExperiencePage/FETCH_AUTHOR_PAYOUTS_ENABLED';

// ================ Reducer ================ //

const initialState = {
  // listing(s)
  id: null,
  showListingError: null,
  sendEnquiryInProgress: false,
  sendEnquiryError: null,
  enquiryModalOpenForListingId: null,
  experienceIds: [],
  queryExperiencesInProgress: false,
  queryExperiencesError: null,

  // users
  userIds: [],
  queryUsersInProgress: false,
  queryUsersError: null,
  authorPayoutsEnabled: false,
  // exceptions
  fetchExceptionsError: null,
  fetchExceptionsInProgress: false,
  availabilityExceptions: [],
  filteredAvailabilityExceptions: [],
  // show
  showListingId: null,
  fetchShowListingInProgress: false,
  fetchShowListingError: null,

  createFreeSubscriptionInProgress: false,
  createFreeSubscriptionError: null,
};

const resultIds = data => data.data.map(d => d.id);

const experiencePageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case QUERY_EXPERIENCES_REQUEST:
      return {
        ...state,
        queryExperiencesInProgress: true,
        queryExperiencesError: null,
      };
    case QUERY_EXPERIENCES_SUCCESS:
      return {
        ...state,
        experienceIds: resultIds(payload.data),
        queryExperiencesInProgress: false,
        queryExperiencesError: null,
      };
    case QUERY_EXPERIENCES_ERROR:
      return {
        ...state,
        experienceIds: [],
        queryExperiencesInProgress: false,
        queryExperiencesError: payload,
      };

    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };

    case QUERY_USERS_REQUEST:
      return {
        ...state,
        queryUsersInProgress: true,
        queryUsersError: null,
      };
    case QUERY_USERS_SUCCESS:
      return {
        ...state,
        userIds: resultIds(payload).filter(i => payload.queryIds.includes(i.uuid)),
        queryUsersInProgress: false,
        queryUsersError: null,
      };
    case QUERY_USERS_ERROR:
      return {
        ...state,
        userIds: [],
        queryUsersInProgress: false,
        queryUsersError: payload,
      };

    case FETCH_SHOW_LISTING_REQUEST:
      return {
        ...state,
        fetchShowListingInProgress: true,
        fetchShowListingError: null,
      };
    case FETCH_SHOW_LISTING_SUCCESS:
      return {
        ...state,
        showListingId: payload.id,
        fetchShowListingInProgress: false,
        fetchShowListingError: null,
      };
    case FETCH_SHOW_LISTING_ERROR:
      return {
        ...state,
        userIds: [],
        fetchShowListingInProgress: false,
        fetchShowListingError: payload,
      };

    case FETCH_AVAILABILITY_EXCEPTIONS_REQUEST:
      return {
        ...state,
        availabilityExceptions: [],
        filteredAvailabilityExceptions: [],
        fetchExceptionsError: null,
        fetchExceptionsInProgress: true,
      };
    case FETCH_AVAILABILITY_EXCEPTIONS_SUCCESS:
      return {
        ...state,
        availabilityExceptions: payload,
        filteredAvailabilityExceptions: payload,
        fetchExceptionsError: null,
        fetchExceptionsInProgress: false,
      };
    case FETCH_AVAILABILITY_EXCEPTIONS_ERROR:
      return {
        ...state,
        fetchExceptionsError: payload,
        fetchExceptionsInProgress: false,
      };
    case FILTER_AVAILABILITY_EXCEPTIONS_SUCCESS:
      return {
        ...state,
        filteredAvailabilityExceptions: payload,
      };

    case CREATE_FREE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        createFreeSubscriptionInProgress: true,
        createFreeSubscriptionError: null,
      };
    case CREATE_FREE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        createFreeSubscriptionInProgress: false,
        createFreeSubscriptionError: null,
      };
    case CREATE_FREE_SUBSCRIPTION_ERROR:
      return {
        ...state,
        createFreeSubscriptionInProgress: false,
        createFreeSubscriptionError: payload,
      };

    case FETCH_AUTHOR_PAYOUTS_ENABLED:
      return {
        ...state,
        authorPayoutsEnabled: payload,
      };

    default:
      return state;
  }
};

export default experiencePageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});
export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const queryExperiencesRequest = () => ({
  type: QUERY_EXPERIENCES_REQUEST,
});
export const queryExperiencesSuccess = response => ({
  type: QUERY_EXPERIENCES_SUCCESS,
  payload: { data: response.data },
});
export const queryExperiencesError = e => ({
  type: QUERY_EXPERIENCES_ERROR,
  error: true,
  payload: e,
});

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

export const queryUsersRequest = () => ({
  type: QUERY_USERS_REQUEST,
});
export const queryUsersSuccess = (response, queryIds) => ({
  type: QUERY_USERS_SUCCESS,
  payload: { data: response.data.data, queryIds },
});
export const queryUsersError = e => ({
  type: QUERY_USERS_ERROR,
  error: true,
  payload: e,
});

export const fetchShowListingRequest = () => ({
  type: FETCH_SHOW_LISTING_REQUEST,
});
export const fetchShowListingSuccess = id => ({
  type: FETCH_SHOW_LISTING_SUCCESS,
  payload: { id },
});
export const fetchShowListingError = e => ({
  type: FETCH_SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

// SDK method: availabilityExceptions.query
export const fetchAvailabilityExceptionsRequest = params => ({
  type: FETCH_AVAILABILITY_EXCEPTIONS_REQUEST,
  payload: { params },
});
export const fetchAvailabilityExceptionsSuccess = availabilityExceptions => ({
  type: FETCH_AVAILABILITY_EXCEPTIONS_SUCCESS,
  payload: availabilityExceptions,
});
export const fetchAvailabilityExceptionsError = e => ({
  type: FETCH_AVAILABILITY_EXCEPTIONS_ERROR,
  error: true,
  payload: e,
});

export const createFreeSubscriptionRequest = () => ({
  type: CREATE_FREE_SUBSCRIPTION_REQUEST,
});
export const createFreeSubscriptionSuccess = () => ({
  type: CREATE_FREE_SUBSCRIPTION_SUCCESS,
});
export const createFreeSubscriptionError = e => ({
  type: CREATE_FREE_SUBSCRIPTION_ERROR,
  error: true,
  payload: e,
});

export const filterAvailabilityExceptionsSuccess = availabilityExceptions => ({
  type: FILTER_AVAILABILITY_EXCEPTIONS_SUCCESS,
  payload: availabilityExceptions,
});

export const fetchAuthorPayoutsEnabledSuccess = payoutsEnabled => ({
  type: FETCH_AUTHOR_PAYOUTS_ENABLED,
  payload: payoutsEnabled,
});

// ================ Thunks ================ //

export const fetchAuthorPayoutsEnabled = authorId => (dispatch, getState, sdk) => {
  dispatch(payoutsEnabled(authorId)).then(payoutsEnabled => {
    dispatch(fetchAuthorPayoutsEnabledSuccess(payoutsEnabled));
  });
};

const fetchListingTeamIds = (listing, dispatch) => {
  const teamFromPublicData = listing.attributes.publicData.team || [];
  const teamIds = teamFromPublicData.filter(i => !i.pending).map(i => i.id);

  return dispatch(queryUsers(teamIds));
};

export const showListing = (listingId, isOwn = false, useIntegration = false) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(showListingRequest(listingId));

  const params = {
    id: listingId,
    ...listingsIncludeParams,
  };

  const show = isOwn
    ? useIntegration
      ? integrationAPI.listings.show({
          ...params,
          id: listingId.uuid,
        })
      : sdk.ownListings.show(params)
    : sdk.listings.show(params);

  return show
    .then(response => {
      const data = isOwn && useIntegration ? response.data : response;
      const listing = denormalisedResponseEntities(data)?.[0];
      const showId = listing.attributes.publicData.showId;

      dispatch(fetchAuthorPayoutsEnabled(listing?.author?.id?.uuid));
      dispatch(addMarketplaceEntities(data));
      dispatch(fetchShowListing(showId));
      fetchListingTeamIds(listing, dispatch);

      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchShowListing = id => (dispatch, getState, sdk) => {
  dispatch(fetchShowListingRequest(id));

  return integrationAPI.listings
    .show({ id })
    .then(response => {
      const listing = response.data.data.data;

      dispatch(addMarketplaceEntities(response.data));
      dispatch(fetchShowListingSuccess(listing.id));
      return listing;
    })
    .catch(e => dispatch(fetchShowListingError(storableError(e))));
};

export const queryExperiences = () => (dispatch, getState, sdk) => {
  dispatch(queryExperiencesRequest());

  return sdk.listings
    .query({
      pub_type: config.listingTypes['experience'],
      per_page: QUERY_EXPERIENCES_RESULT_PAGE_SIZE,
      ...listingsIncludeParams,
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(queryExperiencesSuccess(response));
      return response;
    })
    .catch(e => dispatch(queryExperiencesError(storableError(e))));
};

export const sendEnquiry = (listingId, message) => (dispatch, getState, sdk) => {
  dispatch(sendEnquiryRequest());
  const bodyParams = {
    transition: TRANSITION_ENQUIRE,
    processAlias: config.bookingProcessAlias,
    params: { listingId },
  };
  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(() => {
        dispatch(sendEnquirySuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .catch(e => {
      dispatch(sendEnquiryError(storableError(e)));
      throw e;
    });
};

export const queryUsers = queryIds => async (dispatch, getState, sdk) => {
  dispatch(queryUsersRequest());

  return integrationAPI.users
    .query({
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response.data));
      dispatch(queryUsersSuccess(response.data, queryIds));

      return response.data;
    })
    .catch(e => dispatch(queryUsersError(storableError(e))));
};

export const requestFetchAvailabilityExceptions = fetchParams => (dispatch, getState, sdk) => {
  dispatch(fetchAvailabilityExceptionsRequest(fetchParams));

  return integrationAPI.availabilityExceptions
    .query(fetchParams, { expand: true })
    .then(response => {
      const availabilityExceptions = denormalisedResponseEntities(response.data);
      dispatch(fetchAvailabilityExceptionsSuccess(availabilityExceptions));
      return availabilityExceptions;
    })
    .catch(e => {
      return dispatch(fetchAvailabilityExceptionsError(storableError(e)));
    });
};

export const createFreeSubscription = params => (dispatch, getState, sdk) => {
  dispatch(createFreeSubscriptionRequest());

  const { listingId, userId } = params;

  return integrationAPI.subscriptions
    .createFreeSubscription({ listingId, userId })
    .then(response => {
      dispatch(createFreeSubscriptionSuccess(response.data));
      dispatch(addMarketplaceEntities(response.data));
      dispatch(fetchCurrentUser());

      return response.data;
    })
    .catch(e => {
      return dispatch(createFreeSubscriptionError(storableError(e)));
    });
};

// loadData helper functions
const getCurrentListing = async listingId => {
  const currentListingResponse = await integrationAPI.listings.show({ id: listingId });
  return currentListingResponse.data.data.data;
};
const isInvitedToOrganization = (currentListing, organizationProfiles) => {
  const currentListingOrganizationId = currentListing.attributes.publicData.organizationId;
  return !!organizationProfiles.find(o => o.id === currentListingOrganizationId);
};
const isUserInvitedToTeam = (currentUser, currentListing) => {
  const teamFromPublicData = currentListing.attributes.publicData.team;
  return !!teamFromPublicData?.filter(i => !i.pending)?.find(i => i.id === currentUser?.id?.uuid);
};

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  const listingId = new UUID(params.id);

  const tz = typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

  const today = new Date();
  const start = resetToStartOfDay(today, tz, 0);
  // Query range: today + 364 days
  const exceptionRange = 364;
  const end = resetToStartOfDay(today, tz, exceptionRange);

  // NOTE: in this template, we don't expect more than 100 exceptions.
  // If there are more exceptions, pagination kicks in and we can't use frontend sorting.
  const fetchExceptionsParams = {
    listingId: params.id,
    start,
    end,
  };

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];

  if (ownListingVariants.includes(params.variant)) {
    const { currentUser } = getState().user;

    const currentListing = await getCurrentListing(listingId.uuid);
    const organizationProfiles = await dispatch(fetchOrganizationProfiles());
    const invitedToOrganization = isInvitedToOrganization(currentListing, organizationProfiles);
    const isCurrentUserInvitedToTeam = isUserInvitedToTeam(currentUser, currentListing);
    const useIntegration = invitedToOrganization || isCurrentUserInvitedToTeam;

    const listingPromise = dispatch(showListing(listingId, true, useIntegration));
    const availabilityPromise = dispatch(requestFetchAvailabilityExceptions(fetchExceptionsParams));

    return Promise.all([listingPromise, availabilityPromise]);
  }

  return dispatch(showListing(listingId)).then(() => {
    Promise.all([
      dispatch(fetchComments(listingId)),
      dispatch(saveRecentlyViewedListingId(listingId.uuid)).then(() => {
        dispatch(queryRecentlyViewedListings(listingId.uuid));
      }),
      dispatch(queryExperiences()),
      dispatch(requestFetchAvailabilityExceptions(fetchExceptionsParams)),
    ]);
  });
};
