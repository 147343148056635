import React from 'react';
import loadable from '@loadable/component';
import getPageDataLoadingAPI from './containers/pageDataLoadingAPI';
import PreviewResolverPage from './containers/PreviewResolverPage/PreviewResolverPage';
import { NotFoundPage } from './containers';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from './components';

const pageDataLoadingAPI = getPageDataLoadingAPI();

const AboutPage = loadable(() => import(/* webpackChunkName: "AboutPage" */ './containers/AboutPage/AboutPage'));
const AudiencePage = loadable(() => import(/* webpackChunkName: "AudiencePage" */ './containers/AudiencePage/AudiencePage'));
const ArtistProfilePage = loadable(() => import(/* webpackChunkName: "ArtistProfilePage" */ './containers/ArtistProfilePage/ArtistProfilePage'));
const SignupPage = loadable(() => import(/* webpackChunkName: "SignupPage" */ './containers/SignupPage/SignupPage'));
const LoginPage = loadable(() => import(/* webpackChunkName: "LoginPage" */ './containers/LoginPage/LoginPage'));
const CheckoutPage = loadable(() => import(/* webpackChunkName: "CheckoutPage" */ './containers/CheckoutPage/CheckoutPage'));
const CMSPage = loadable(() => import(/* webpackChunkName: "CMSPage" */ './containers/CMSPage/CMSPage'));
const BookingPage = loadable(() => import(/* webpackChunkName: "BookingPage" */ './containers/BookingPage/BookingPage'));
const ContactDetailsPage = loadable(() => import(/* webpackChunkName: "ContactDetailsPage" */ './containers/ContactDetailsPage/ContactDetailsPage'));
const EditListingPage = loadable(() => import(/* webpackChunkName: "EditListingPage" */ './containers/EditListingPage/EditListingPage'));
const EditExperiencePage = loadable(() => import(/* webpackChunkName: "EditExperiencePage" */ './containers/EditExperiencePage/EditExperiencePage'));
const EmailVerificationPage = loadable(() => import(/* webpackChunkName: "EmailVerificationPage" */ './containers/EmailVerificationPage/EmailVerificationPage'));
const InboxPage = loadable(() => import(/* webpackChunkName: "InboxPage" */ './containers/InboxPage/InboxPage'));
const OrdersPage = loadable(() => import(/* webpackChunkName: "OrdersPage" */ './containers/OrdersPage/OrdersPage'));
const LandingPage = loadable(() => import(/* webpackChunkName: "LandingPage" */ './containers/LandingPage/LandingPage'));
const ListingPage = loadable(() => import(/* webpackChunkName: "ListingPage" */ /* webpackPrefetch: true */ './containers/ListingPage/ListingPage'));
const ExperiencePage = loadable(() => import(/* webpackChunkName: "ExperiencePage" */ './containers/ExperiencePage/ExperiencePage'));
const ManageListingsPage = loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ './containers/ManageListingsPage/ManageListingsPage'));
const PasswordChangePage = loadable(() => import(/* webpackChunkName: "PasswordChangePage" */ './containers/PasswordChangePage/PasswordChangePage'));
const PasswordRecoveryPage = loadable(() => import(/* webpackChunkName: "PasswordRecoveryPage" */ './containers/PasswordRecoveryPage/PasswordRecoveryPage'));
const PasswordResetPage = loadable(() => import(/* webpackChunkName: "PasswordResetPage" */ './containers/PasswordResetPage/PasswordResetPage'));
const PaymentMethodsPage = loadable(() => import(/* webpackChunkName: "PaymentMethodsPage" */ './containers/PaymentMethodsPage/PaymentMethodsPage'));
const PrivacyPolicyPage = loadable(() => import(/* webpackChunkName: "PrivacyPolicyPage" */ './containers/PrivacyPolicyPage/PrivacyPolicyPage'));
const CommunityGuidelinesPage = loadable(() => import(/* webpackChunkName: "CommunityGuidelinesPage" */ './containers/CommunityGuidelinesPage/CommunityGuidelinesPage'));
const ProfilePage = loadable(() => import(/* webpackChunkName: "ProfilePage" */ './containers/ProfilePage/ProfilePage'));
const ProfileSettingsPage = loadable(() => import(/* webpackChunkName: "ProfileSettingsPage" */ './containers/ProfileSettingsPage/ProfileSettingsPage'));
const StripePayoutPage = loadable(() => import(/* webpackChunkName: "StripePayoutPage" */ './containers/StripePayoutPage/StripePayoutPage'));
const TermsOfServicePage = loadable(() => import(/* webpackChunkName: "TermsOfServicePage" */ './containers/TermsOfServicePage/TermsOfServicePage'));
const TransactionPage = loadable(() => import(/* webpackChunkName: "TransactionPage" */ './containers/TransactionPage/TransactionPage'));
const OrganizationSettingsPage = loadable(() => import(/* webpackChunkName: "OrganizationSettingsPage" */ './containers/OrganizationSettingsPage/OrganizationSettingsPage'));
const StreamPage = loadable(() => import(/* webpackChunkName: "StreamPage" */ './containers/StreamPage/StreamPage'));
const QuickRepliesPage = loadable(() => import(/* webpackChunkName: "QuickRepliesPage" */ './containers/QuickRepliesPage/QuickRepliesPage'));
const PaymentHistoryPage = loadable(() => import(/* webpackChunkName: "PaymentHistoryPage" */ './containers/PaymentHistoryPage/PaymentHistoryPage'));
const OrderHistoryPage = loadable(() => import(/* webpackChunkName: "OrderHistoryPage" */ './containers/OrderHistoryPage/OrderHistoryPage'));
const AccountSettingsPage = loadable(() => import(/* webpackChunkName: "AccountSettingsPage" */ './containers/AccountSettingsPage/AccountSettingsPage'));
const OrganizationProfilePage = loadable(() => import(/* webpackChunkName: "OrganizationProfilePage" */ './containers/OrganizationProfilePage/OrganizationProfilePage'));
const MeetPage = loadable(() => import(/* webpackChunkName: "MeetPage" */ './containers/MeetPage/MeetPage'));
const ManageSubscriptionsPage = loadable(() => import(/* webpackChunkName: "ManageSubscriptionsPage" */ './containers/ManageSubscriptionsPage/ManageSubscriptionsPage'));
const CalendarPage = loadable(() => import(/* webpackChunkName: "CalendarPage" */ './containers/CalendarPage/CalendarPage'));
const CreatorsPage = loadable(() => import(/* webpackChunkName: "CreatorsPage" */ './containers/CreatorsPage/CreatorsPage'));
const HelpPage = loadable(() => import(/* webpackChunkName: "HelpPage" */ './containers/HelpPage/HelpPage'));
const TopicPage = loadable(() => import(/* webpackChunkName: "TopicPage" */ './containers/HelpPage/TopicPage/TopicPage'));
const ArticlePage = loadable(() => import(/* webpackChunkName: "ArticlePage" */ './containers/HelpPage/ArticlePage/ArticlePage'));
const ChangelogPage = loadable(() => import(/* webpackChunkName: "ChangelogPage" */ './containers/ChangelogPage/ChangelogPage'));
const AnalyticsPage = loadable(() => import(/* webpackChunkName: "AnalyticsPage" */ './containers/AnalyticsPage/AnalyticsPage'));
const MembersPage = loadable(() => import(/* webpackChunkName: "MembersPage" */ './containers/MembersPage/MembersPage'));
const TeamPermissionPage = loadable(() => import(/* webpackChunkName: "TeamPermissionPage" */ './containers/TeamPermissionPage/TeamPermissionPage'));
const VerifyOrganizationInvitePage = loadable(() => import(/* webpackChunkName: "VerifyOrganizationInvitePage" */ './containers/VerifyOrganizationInvitePage/VerifyOrganizationInvitePage'));
const VerifyListingInvitePage = loadable(() => import(/* webpackChunkName: "VerifyListingInvitePage" */ './containers/VerifyListingInvitePage/VerifyListingInvitePage'));
const ReviewsPage = loadable(() => import(/* webpackChunkName: "ReviewsPage" */ /* webpackPrefetch: true */ './containers/ReviewsPage/ReviewsPage'));
const CommentsPage = loadable(() => import(/* webpackChunkName: "CommentsPage" */ /* webpackPrefetch: true */ './containers/CommentsPage/CommentsPage'));
const RoleSelectionPage = loadable(() => import(/* webpackChunkName: "RoleSelectionPage" */ /* webpackPrefetch: true */ './containers/RoleSelectionPage/RoleSelectionPage'));
const HostsPage = loadable(() => import(/* webpackChunkName: "HostsPage" */ /* webpackPrefetch: true */ './containers/HostsPage/HostsPage'));
const CommunityPage = loadable(() => import(/* webpackChunkName: "CommunityPage" */ /* webpackPrefetch: true */ './containers/CommunityPage/CommunityPage'));
const PostPage = loadable(() => import(/* webpackChunkName: "PostPage" */ './containers/PostPage/PostPage'));

// Styleguide helps you to review current components and develop new ones
const StyleguidePage = loadable(() => import(/* webpackChunkName: "StyleguidePage" */ './containers/StyleguidePage/StyleguidePage'));

export const ACCOUNT_SETTINGS_PAGES = [
  'ProfileSettingsPage',
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
];

export const DASHBOARD_PAGES = [
  'ManageListingsPage',
  'StreamPage',
  'InboxPage',
  'SaleDetailsPage',
  'QuickRepliesPage',
  'AnalyticsPage',
  'MembersPage',
  'OrganizationSettingsPage',
  'TeamPermissionPage',
  'VerifyOrganizationInvitePage',
  'PaymentHistoryPage'
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
  return [
    {
      path: '/',
      name: 'LandingPage',
      component: LandingPage,
      loadData: pageDataLoadingAPI.LandingPage.loadData,
    },
    {
      path: '/about',
      name: 'AboutPage',
      component: AboutPage,
    },
    {
      path: '/audience',
      name: 'AudiencePage',
      component: AudiencePage,
    },
    {
      path: '/a/:id',
      name: 'ArtistProfilePage',
      component: ArtistProfilePage,
      loadData: pageDataLoadingAPI.ArtistProfilePage.loadData,
    },
    {
      path: '/creators',
      name: 'CreatorsPage',
      component: CreatorsPage,
    },
    // Redirect from SearchPage to NotFoundPage while in beta
    {
      path: '/s',
      name: 'SearchPage',
      component: () => <NamedRedirect name="NotFoundPage" />,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/p/:pageId',
      name: 'CMSPage',
      component: CMSPage,
      loadData: pageDataLoadingAPI.CMSPage.loadData,
    },
    // ListingPage (ShowPage)
    {
      path: '/show',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/show/:slug/:id',
      name: 'ListingPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/show/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/show/new',
      name: 'NewListingPage',
      auth: true,
      authPage: 'ArtistSignupPage',
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'basic' }}
        />
      ),
    },
    {
      path: '/show/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    {
      path: '/show/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    // ExperiencePage
    {
      path: '/experience',
      name: 'ExperienceBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/experience/:slug/:id',
      name: 'ExperiencePage',
      component: ExperiencePage,
      loadData: pageDataLoadingAPI.ExperiencePage.loadData,
    },
    {
      path: '/experience/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: CheckoutPage,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
    },
    {
      path: '/experience/:slug/:id/booking',
      name:'BookingPage',
      auth: true,
      component: BookingPage,
      setInitialValues: pageDataLoadingAPI.BookingPage.setInitialValues,
    },
    {
      path: '/experience/:slug/:id/:variant',
      name: 'ExperiencePageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: ExperiencePage,
      loadData: pageDataLoadingAPI.ExperiencePage.loadData,
    },
    {
      path: '/experience/new',
      name: 'NewExperiencePage',
      auth: true,
      authPage: 'ArtistSignupPage',
      component: () => (
        <NamedRedirect
          name="EditExperiencePage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'details' }}
        />
      ),
    },
    {
      path: '/experience/:slug/:id/:type/:tab',
      name: 'EditExperiencePage',
      auth: true,
      component: EditExperiencePage,
      loadData: pageDataLoadingAPI.EditExperiencePage.loadData,
    },
    {
      path: '/experience/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditExperienceStripeOnboardingPage',
      auth: true,
      component: EditExperiencePage,
      loadData: pageDataLoadingAPI.EditExperiencePage.loadData,
    },
    // ReviewsPage
    {
      path: '/:slug/:id/reviews',
      name: 'ReviewsPage',
      component: ReviewsPage,
      loadData: pageDataLoadingAPI.ReviewsPage.loadData,
    },
    // CommentsPage
    {
      path: '/:slug/:id/comments',
      name: 'CommentsPage',
      component: CommentsPage,
      loadData: pageDataLoadingAPI.CommentsPage.loadData,
    },
    // HostsPage
    {
      path: '/:slug/:id/hosts',
      name: 'HostsPage',
      component: HostsPage,
      loadData: pageDataLoadingAPI.HostsPage.loadData,
    },
    {
      path: '/community',
      name: 'CommunityPage',
      component: CommunityPage,
      loadData: pageDataLoadingAPI.CommunityPage.loadData,
    },
    {
      path: '/community/:slug?/:id',
      name: 'PostPage',
      component: PostPage,
      loadData: pageDataLoadingAPI.PostPage.loadData,
    },
    // Canonical path should be after the `/show/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/show/:id',
      name: 'ListingPageCanonical',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/user',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/user/:id',
      name: 'ProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/account/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileSettingsPage,
    },
    // Audience pages (ex. audience dashboard pages)
    {
      path: '/subscriptions',
      name: 'ManageSubscriptionsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ManageSubscriptionsPage,
      loadData: pageDataLoadingAPI.ManageSubscriptionsPage.loadData,
    },
    {
      path: '/calendar',
      name: 'CalendarPage',
      auth: true,
      authPage: 'LoginPage',
      component: CalendarPage,
      loadData: pageDataLoadingAPI.CalendarPage.loadData,
    },
    {
      path: '/inbox',
      name: 'OrdersPage',
      auth: true,
      authPage: 'LoginPage',
      component: OrdersPage,
      loadData: pageDataLoadingAPI.OrdersPage.loadData,
    },
    {
      path: '/order-history',
      name: 'OrderHistoryPage',
      auth: true,
      authPage: 'LoginPage',
      component: OrderHistoryPage,
      loadData: pageDataLoadingAPI.OrderHistoryPage.loadData,
    },
    // Artist dashboard pages
    {
      path: '/artist/dashboard',
      name: 'ArtistDashboardPage',
      auth: true,
      authPage: 'LoginPage',
      component: ManageListingsPage,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },
    {
      path: '/dashboard/listings',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ManageListingsPage,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    }, 
    {
      path: '/dashboard/stream',
      name: 'StreamPage',
      auth: true,
      authPage: 'LoginPage',
      component: StreamPage,
      loadData: pageDataLoadingAPI.StreamPage.loadData,
    },
    {
      path: '/dashboard/inbox',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: InboxPage,
      loadData: pageDataLoadingAPI.InboxPage.loadData,
    },
    {
      path: '/dashboard/quick-replies',
      name: 'QuickRepliesPage',
      auth: true,
      authPage: 'LoginPage',
      component: QuickRepliesPage,
      loadData: pageDataLoadingAPI.QuickRepliesPage.loadData,
    },
    {
      path: '/dashboard/analytics',
      name: 'AnalyticsPage',
      auth: true,
      authPage: 'LoginPage',
      component: AnalyticsPage,
      loadData: pageDataLoadingAPI.AnalyticsPage.loadData,
    },
    {
      path: '/dashboard/members',
      name: 'MembersPage',
      auth: true,
      authPage: 'LoginPage',
      component: MembersPage,
      loadData: pageDataLoadingAPI.MembersPage.loadData,
    },
    {
      path: '/dashboard/organization-settings',
      name: 'OrganizationSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: OrganizationSettingsPage,
      loadData: pageDataLoadingAPI.OrganizationSettingsPage.loadData,
    },   
    {
      path: '/dashboard/team-permission',
      name: 'TeamPermissionPage',
      auth: true,
      authPage: 'LoginPage',
      component: TeamPermissionPage,
      loadData: pageDataLoadingAPI.TeamPermissionPage.loadData,
    },   
    {
      path: '/dashboard/team-permission/verify-invite',
      name: 'VerifyOrganizationInvitePage',
      auth: true,
      authPage: 'LoginPage',
      component: VerifyOrganizationInvitePage,
      loadData: pageDataLoadingAPI.VerifyOrganizationInvitePage.loadData,
    },
    {
      path: '/dashboard/team-permission/signup/verify-invite',
      name: 'VerifyOrganizationInvitePageWithSignup',
      auth: true,
      authPage: 'ArtistSignupPage',
      component: VerifyOrganizationInvitePage,
      loadData: pageDataLoadingAPI.VerifyOrganizationInvitePage.loadData,
    },
    {
      path: '/verify-invite',
      name: 'VerifyListingInvitePage',
      auth: true,
      authPage: 'LoginPage',
      component: VerifyListingInvitePage,
      loadData: pageDataLoadingAPI.VerifyListingInvitePage.loadData,
    },      
    {
      path: '/signup/verify-invite',
      name: 'VerifyListingInvitePageWithSignup',
      auth: true,
      authPage: 'ArtistSignupPage',
      component: VerifyListingInvitePage,
      loadData: pageDataLoadingAPI.VerifyListingInvitePage.loadData,
    },  
    {
      path: '/dashboard/organization-settings/transaction-history',
      name: 'PaymentHistoryPage',
      auth: true,
      authPage: 'LoginPage',
      component: PaymentHistoryPage,
      loadData: pageDataLoadingAPI.PaymentHistoryPage.loadData,
    },
    {
      path: '/o/:id',
      name: 'OrganizationProfilePage',
      component: OrganizationProfilePage,
      loadData: pageDataLoadingAPI.OrganizationProfilePage.loadData,
    },
    {
      path: '/meet/:id',
      name: 'MeetPage',
      auth: true,
      authPage: 'LoginPage',
      component: MeetPage,
      loadData: pageDataLoadingAPI.MeetPage.loadData,
    },
    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {  
      path: '/role-selection',
      name: 'RoleSelectionPage',
      component: RoleSelectionPage,     
    },
    {
      path: '/login',
      name: 'LoginPage',
      component: LoginPage,
      extraProps: { tab: 'login'},
    },
    {
      path: '/signup/:role/:step?',
      name: 'SignupPage',
      component: SignupPage,
      extraProps: { tab: 'signup' },
    },
    {
      path: '/signup/artist',
      name: 'ArtistSignupPage',
      component: SignupPage,
      extraProps: { tab: 'signup' },
    },
    {
      path: '/signup/audience',
      name: 'AudienceSignupPage',
      component: SignupPage,
      extraProps: { tab: 'signup' },
    },
    {
      path: '/confirm-login',
      name: 'ConfirmLoginPage',
      component: LoginPage,
      extraProps: { tab: 'confirm' },
    },
    {
      path: '/confirm-signup/:role',
      name: 'ConfirmSignupPage',
      component: SignupPage,
      extraProps: { tab: 'confirm' },
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: PasswordRecoveryPage,
    },
    {
      path: '/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'customer' },
      loadData: pageDataLoadingAPI.TransactionPage.loadData,
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'provider' },
      loadData: pageDataLoadingAPI.TransactionPage.loadData,
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: AccountSettingsPage,
    },
    {
      path: '/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ContactDetailsPage,
      loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
    },
    {
      path: '/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: PasswordChangePage,
    },
    {
      path: '/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: PaymentMethodsPage,
      loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: TermsOfServicePage,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: PrivacyPolicyPage,
    },
    {
      path: '/community-guidelines',
      name: 'CommunityGuidelinesPage',
      component: CommunityGuidelinesPage,
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: StyleguidePage,
      extraProps: { raw: true },
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },
    {
      path: '/help',
      name: 'HelpPage',
      component: HelpPage,
    },
    {
      path: '/help/:topicId',
      name: 'TopicPage',
      component: TopicPage,
    },
    {
      path: '/help/:topicId/:articleId',
      name: 'ArticlePage',
      component: ArticlePage,
    },
    {
      path: '/changelog',
      name: 'ChangelogPage',
      component: ChangelogPage,
    },
    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: PasswordResetPage ,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: EmailVerificationPage,
      loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
    },
     // Do not change this path!
    //
    // The API expects that the application implements /preview endpoint
    {
      path: '/preview',
      name: 'PreviewResolverPage',
      component: PreviewResolverPage ,
    },
  ];
};

export default routeConfiguration;
