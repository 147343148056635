import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import { util as sdkUtil } from '../../util/sdkLoader';
import config from '../../config';

const LANDING_PAGE_LISTINGS_COUNT = 24;
const LANDING_PAGE_EXPERIENCES_COUNT = 9;

// ================ Action types ================ //

export const QUERY_LISTINGS_REQUEST = 'app/LandingPage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/LandingPage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/LandingPage/QUERY_LISTINGS_ERROR';

export const QUERY_EXPERIENCES_REQUEST = 'app/LandingPage/QUERY_EXPERIENCES_REQUEST';
export const QUERY_EXPERIENCES_SUCCESS = 'app/LandingPage/QUERY_EXPERIENCES_SUCCESS';
export const QUERY_EXPERIENCES_ERROR = 'app/LandingPage/QUERY_EXPERIENCES_ERROR';

// ================ Reducer ================ //

const initialState = {
  listingIds: [],
  queryListingsInProgress: false,
  queryListingsError: null,
  experienceIds: [],
  queryExperiencesInProgress: false,
  queryExperiencesError: null,
};

const resultIds = data => data.data.map(l => l.id);

export default function landingPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,
        queryListingsInProgress: true,
        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return {
        ...state,
        queryListingsInProgress: false,
        listingIds: resultIds(payload.data),
      };
    case QUERY_LISTINGS_ERROR:
      return { ...state, listingRefs: [], queryListingsError: payload };

    case QUERY_EXPERIENCES_REQUEST:
      return {
        ...state,
        queryExperiencesInProgress: true,
        queryExperiencesError: null,
      };
    case QUERY_EXPERIENCES_SUCCESS:
      return {
        ...state,
        queryExperiencesInProgress: false,
        experienceIds: resultIds(payload.data),
      };
    case QUERY_EXPERIENCES_ERROR:
      return { ...state, listingRefs: [], queryExperiencesError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const queryListingsRequest = () => ({
  type: QUERY_LISTINGS_REQUEST,
});

export const queryListingsSuccess = response => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryExperiencesRequest = () => ({
  type: QUERY_EXPERIENCES_REQUEST,
});

export const queryExperiencesSuccess = response => ({
  type: QUERY_EXPERIENCES_SUCCESS,
  payload: { data: response.data },
});

export const queryExperiencesError = e => ({
  type: QUERY_EXPERIENCES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryListings = () => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest());
  return sdk.listings
    .query({
      pub_type: config.listingTypes['show'],
      perPage: LANDING_PAGE_LISTINGS_COUNT,
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.landscape-crop4x',
        'variants.landscape-crop6x',
        'variants.hero-scale',
        'variants.hero-crop',

        // custom variants
        'variants.portrait-crop',
        'variants.portrait-crop2x',
      ],
      'imageVariant.portrait-crop': sdkUtil.objectQueryString({
        w: 280,
        h: 392,
        fit: 'crop',
      }),
      'imageVariant.portrait-crop2x': sdkUtil.objectQueryString({
        w: 560,
        h: 784,
        fit: 'crop',
      }),
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess(response));

      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const queryExperiences = () => (dispatch, getState, sdk) => {
  dispatch(queryExperiencesRequest());
  return sdk.listings
    .query({
      pub_type: config.listingTypes['experience'],
      perPage: LANDING_PAGE_EXPERIENCES_COUNT,
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.landscape-crop4x',
        'variants.landscape-crop6x',
        'variants.hero-scale',
        'variants.hero-crop',

        // custom variants
        'variants.portrait-crop',
        'variants.portrait-crop2x',
      ],
      'imageVariant.portrait-crop': sdkUtil.objectQueryString({
        w: 280,
        h: 392,
        fit: 'crop',
      }),
      'imageVariant.portrait-crop2x': sdkUtil.objectQueryString({
        w: 560,
        h: 784,
        fit: 'crop',
      }),
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(queryExperiencesSuccess(response));

      return response;
    })
    .catch(e => dispatch(queryExperiencesError(storableError(e))));
};

export const loadData = () => (dispatch, getState, sdk) => {
  return Promise.all([dispatch(queryListings()), dispatch(queryExperiences())]);
};
