import React, { useState, useEffect } from 'react';
import { bool, func, object, string, node } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES, DASHBOARD_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { Logo, NamedLink } from '../../components';
import { TopbarSearchForm } from '../../forms';
import config from '../../config';
import classNames from 'classnames';

import { MenuArtist, MenuAudience, MenuUnauthorized } from './topbarMenus';
import css from './TopbarDesktop.module.css';

const SHOW_SEARCH_FORM = false;

const currentPageClass = (page, currentPage) => {
  const isAccountSettingsPage =
    page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
  const isDashboardPage = page === 'ArtistDashboardPage' && DASHBOARD_PAGES.includes(currentPage);

  return isAccountSettingsPage || isDashboardPage || currentPage === page ? css.currentPage : null;
};

const TopbarDesktop = props => {
  const {
    rootClassName,
    className,
    contentClassName,
    logoClassName,
    currentUser,
    currentPage,
    intl,
    isAuthenticated,
    logoFormat,
    onLogout,
    onSearchSubmit,
    onKeywordChange,
    keywords,
    initialSearchFormValues,
    topbarSearchRef,
    isTopbarSearchFocused,
    handleTopbarSearchFocus,
  } = props;

  const [mounted, setMounted] = useState(false);
  const [isUnauthenticatedMenuOpen, setIsUnauthenticatedMenuOpen] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const handleLogout = () => {
    onLogout();
    setIsUnauthenticatedMenuOpen(false);
  };

  const isCurrentUserArtist =
    currentUser?.attributes?.profile?.publicData?.role === config.userRoles.artist;
  const isDashboardPage = DASHBOARD_PAGES.includes(currentPage);
  const isAccountSettingsPage = ACCOUNT_SETTINGS_PAGES.includes(currentPage);
  const authenticatedOnClientSide = mounted && isAuthenticated;

  const menuItemBorderClasses = classNames(css.menuItemBorder, {
    [css.menuItemBorderArtist]: isDashboardPage,
  });

  const profileMenu = authenticatedOnClientSide ? (
    isDashboardPage ? (
      <MenuArtist
        menuItemBorderClasses={menuItemBorderClasses}
        currentUser={currentUser}
        currentPageClass={page => currentPageClass(page, currentPage)}
        handleLogout={handleLogout}
      />
    ) : (
      <MenuAudience
        menuItemBorderClasses={menuItemBorderClasses}
        currentUser={currentUser}
        currentPage={currentPage}
        currentPageClass={page => currentPageClass(page, currentPage)}
        isCurrentUserArtist={isCurrentUserArtist}
        handleLogout={handleLogout}
      />
    )
  ) : (
    <MenuUnauthorized
      menuItemBorderClasses={menuItemBorderClasses}
      currentPageClass={page => currentPageClass(page, currentPage)}
      isUnauthenticatedMenuOpen={isUnauthenticatedMenuOpen}
      setIsUnauthenticatedMenuOpen={setIsUnauthenticatedMenuOpen}
    />
  );

  const logoContainerAndLink = (
    <div
      className={classNames(css.logoContainer, { [css.dashboardLogoContainer]: isDashboardPage })}
    >
      <NamedLink
        className={css.logoLink}
        name={isDashboardPage ? 'ArtistDashboardPage' : 'LandingPage'}
      >
        <Logo
          className={classNames(css.logo, logoClassName, { [css.dashboardLogo]: isDashboardPage })}
          format={logoFormat || 'desktop'}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>
    </div>
  );

  const search = !isDashboardPage && SHOW_SEARCH_FORM && (
    <TopbarSearchForm
      onSubmit={onSearchSubmit}
      onKeywordChange={onKeywordChange}
      keywords={keywords}
      initialValues={initialSearchFormValues}
      topbarSearchRef={topbarSearchRef}
      isTopbarSearchFocused={isTopbarSearchFocused}
      handleTopbarSearchFocus={handleTopbarSearchFocus}
    />
  );

  const aboutPageLink = !isDashboardPage && (
    <NamedLink name="AboutPage" className={css.mainNavigationLink}>
      <FormattedMessage id="TopbarDesktop.about" />
    </NamedLink>
  );

  const startInvolvingLink = !isDashboardPage && (
    <NamedLink name="AudiencePage" className={css.mainNavigationLink}>
      <FormattedMessage id="TopbarDesktop.startInvolving" />
    </NamedLink>
  );

  const startAttractingLink = !isDashboardPage && (
    <NamedLink name="CreatorsPage" className={css.mainNavigationLink}>
      <FormattedMessage id="TopbarDesktop.startAttracting" />
    </NamedLink>
  );

  const classes = classNames(rootClassName || css.root, className);
  const contentClasses = classNames(contentClassName, css.content, {
    [css.contentWithWidth]: isAccountSettingsPage,
  });

  return (
    <nav className={classes}>
      <div className={contentClasses}>
        {logoContainerAndLink}
        {search}
        <div className={css.navigationLinks}>
          <div className={css.navigationLinksContent}>
            {aboutPageLink}
            {startInvolvingLink}
            {startAttractingLink}
            {profileMenu}
          </div>
        </div>
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  contentClassName: null,
  logoClassName: null,
  currentUser: null,
  currentPage: null,
  logoFormat: null,
  keywords: null,
  isTopbarSearchFocused: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  contentClassName: string,
  logoClassName: string,
  currentUser: propTypes.currentUser,
  currentPage: string,
  intl: intlShape.isRequired,
  isAuthenticated: bool.isRequired,
  logoFormat: string,
  onLogout: func.isRequired,
  onSearchSubmit: func.isRequired,
  onKeywordChange: func.isRequired,
  keywords: string,
  initialSearchFormValues: object,
  isTopbarSearchFocused: bool.isRequired,
  handleTopbarSearchFocus: func.isRequired,
};

export default TopbarDesktop;
