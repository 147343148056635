import { Children } from 'react';

// Dynamic breakpoints
export const MAX_MOBILE_SCREEN_WIDTH = 768;
export const MAX_TABLET_SCREEN_WIDTH = 1128;

// Default slide counts
export const SLIDES_TO_SHOW = 1.5;
export const SLIDES_TO_SHOW_TABLET = 2;
export const SLIDES_TO_SHOW_DESKTOP = 3;

// slidesToScroll
export const SLIDES_TO_SCROLL = 1.5;
export const SLIDES_TO_SCROLL_TABLET = 2;
export const SLIDES_TO_SCROLL_DESKTOP = 3;

// Max slidesToShow
export const MAX_MOBILE_SLIDES_TO_SHOW = 2;
export const MAX_TABLET_SLIDES_TO_SHOW = 5;
export const MAX_DESKTOP_SLIDES_TO_SHOW = 5;

// Helper for throttling window resize events
const THROTTLE_TIME = 200;

/**
 * Throttle function to limit the execution of a given function.
 *
 * @param {Function} fn - The function to be throttled.
 * @param {number} wait - The minimum wait time (in milliseconds) between executions.
 *
 * @return {Function} A throttled version of the input function that will only execute once
 *                    per the specified wait time.
 */
const throttle = (fn, wait) => {
  let lastCall = 0;
  return function(...args) {
    const now = new Date().getTime();
    if (now - lastCall < wait) {
      return;
    }
    lastCall = now;
    return fn(...args);
  };
};

/**
 * Calculate the total number of slider items
 *
 * @param {node} children
 * @return {number}
 */
export const totalSlidesFn = children => {
  const sliderChildren = Children.toArray(children);
  return sliderChildren.length || 0;
};

/**
 * Responsive settings for slider
 *
 * @param {object} slidesToShow
 * @param {object} slidesToScroll
 * @param {object} viewport
 * @param {string} sliderId
 *
 * @return {object}
 */
export const responsiveSettingsFn = (slidesToShow, slidesToScroll, viewport, sliderId) => {
  let sliderSlidesToShow = slidesToShow.desktop || SLIDES_TO_SHOW_DESKTOP;
  let sliderSlidesToScroll = slidesToScroll.desktop || SLIDES_TO_SCROLL_DESKTOP;

  const handleWarning = (type, max, device) => {
    if (slidesToShow[type] > max) {
      console.warn(
        `${sliderId} slidesToShow should show fewer than ${max + 1} items on ${device}.`
      );
    }
  };

  if (viewport.width < MAX_MOBILE_SCREEN_WIDTH) {
    sliderSlidesToShow = slidesToShow.mobile || SLIDES_TO_SHOW;
    sliderSlidesToScroll = slidesToScroll.mobile || SLIDES_TO_SCROLL;
    handleWarning('mobile', 2, 'mobile');
  } else if (viewport.width < MAX_TABLET_SCREEN_WIDTH) {
    sliderSlidesToShow = slidesToShow.tablet || SLIDES_TO_SHOW_TABLET;
    sliderSlidesToScroll = slidesToScroll.tablet || SLIDES_TO_SCROLL_TABLET;
    handleWarning('tablet', 5, 'tablet');
  }

  return {
    slidesToShow: sliderSlidesToShow,
    slidesToScroll: sliderSlidesToScroll,
  };
};

/**
 * Calculate the max width for slider items, ensuring the last visible item fits within the container
 * with its margin-right intact.
 *
 * @param {number} slidesToShow
 * @param {object} viewport
 * @param {number} margin optional
 * @return {string} maxWidth property
 */
export const slideMaxWidthFn = (slidesToShow, viewport, margin = 16) => {
  const totalWidth = viewport.width < MAX_MOBILE_SCREEN_WIDTH ? '100vw' : '100%';
  const calcMargin = viewport.width < MAX_MOBILE_SCREEN_WIDTH ? 16 : margin;

  // Calculate the width of each item, ensuring the last visible item fits fully with its margin.
  const widthCalculation = `calc((${totalWidth} - (${slidesToShow -
    1} * ${calcMargin}px)) / ${slidesToShow})`;

  return widthCalculation;
};

/**
 * Throttled resize listener to trigger recalculations on window resize
 */
export const listenToResize = callback => {
  const throttledResize = throttle(() => {
    const viewport = { width: window.innerWidth, height: window.innerHeight };
    callback(viewport);
  }, THROTTLE_TIME);

  window.addEventListener('resize', throttledResize);

  return () => window.removeEventListener('resize', throttledResize);
};
