import React from 'react';
import PropTypes from 'prop-types';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { Modal, ResponsiveImage, PrimaryButton, SecondaryButton } from '..';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import classNames from 'classnames';

import css from './DonateModal.module.css';

const { Money } = sdkTypes;

const Donation = props => {
  const { intl, donation, isOwnListing, handlePayment } = props;

  const donationPrice = new Money(
    donation.price.amount,
    config.currency || donation.price.currency
  );
  const formattedDonationPrice = formatMoney(intl, donationPrice);
  return (
    <div className={css.donation}>
      <div>
        <h3 className={css.donationName}>{formattedDonationPrice}</h3>
        <span>{donation.label}</span>
      </div>
      <PrimaryButton
        type="button"
        className={css.donateButton}
        onClick={() => handlePayment({ donation, paymentType: config.paymentTypes['donation'] })}
        disabled={isOwnListing}
      >
        <FormattedMessage id="DonateModal.donationButtonText" />
      </PrimaryButton>
    </div>
  );
};

const DonateModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    isOwnListing,
    listing,
    authorDisplayName,
    authorPayoutsEnabled,
    donationConfig,
    handlePayment,
    onCloseModal,
    onOpenEnquiryModal,
    onManageDisableScrolling,
  } = props;

  const { title } = listing.attributes;

  const hasImages = listing?.images?.length > 0;
  const firstImage = hasImages ? listing.images[0] : null;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      removeCloseMessage
    >
      <div className={css.modalHeader}>
        <ResponsiveImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={['landscape-crop', 'landscape-crop2x']}
        />
        <div className={css.modalHeaderContent}>
          <h2 className={css.modalTitle}>
            <FormattedMessage id="DonateModal.title" />
          </h2>
          <p className={css.modalSubTitle}>{title}</p>
        </div>
      </div>
      {authorPayoutsEnabled || isOwnListing ? (
        <div className={css.donations}>
          {donationConfig.map(d => {
            return (
              <Donation
                key={d.id}
                intl={intl}
                donation={d}
                isOwnListing={isOwnListing}
                handlePayment={handlePayment}
              />
            );
          })}
        </div>
      ) : (
        <div className={css.donations}>
          <p className={css.noDonationsText}>
            <FormattedMessage
              id="DonateModal.noDonations"
              values={{ authorDisplayName: <strong>{authorDisplayName}</strong> }}
            />
          </p>
        </div>
      )}
      {!isOwnListing ? (
        <div className={css.modalFooter}>
          {authorPayoutsEnabled ? (
            <span className={css.contactNotice}>
              <FormattedMessage id="DonateModal.contactNotice" />
            </span>
          ) : null}
          <SecondaryButton className={css.contactButton} onClick={onOpenEnquiryModal}>
            <FormattedMessage id="DonateModal.contact" values={{ authorDisplayName }} />
          </SecondaryButton>
        </div>
      ) : null}
    </Modal>
  );
};

DonateModal.defaultProps = {
  className: null,
  rootClassName: null,
  id: null,
  isOpen: false,
  authorPayoutsEnabled: false,
};

const { string, bool, oneOfType, array, func, object } = PropTypes;

DonateModal.propTypes = {
  className: string,
  rootClassName: string,
  id: string,
  isOpen: bool.isRequired,
  isOwnListing: bool.isRequired,
  listing: oneOfType([propTypes.listing, propTypes.ownListing, object]).isRequired,
  authorDisplayName: string,
  authorPayoutsEnabled: bool.isRequired,
  perksConfig: array,
  onOpenEnquiryModal: func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(DonateModal);
